import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import CustomersLogos from '../components/CustomersLogos';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapPrintPage');

    const icons = [{
        icon: 'icon-export',
        text: t('TITLE_ICON_EXPORT')
    }, {
        icon: 'icon-map22',
        text: t('TITLE_ICON_PARAMETERS')
    },
    {
        icon: 'icon-address',
        text: t('TITLE_ICON_CREATE_CUSTOMIZE')
    }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_PRINT')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_PRINT')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos t
                        title={t('TITLE_WEMAP_PRINT')}
                        size='large'
                        customersLogos={[
                            {image: '/images/products/wemap-print/ratp-mds.svg'},
                            {image: '/images/products/wemap-developers/logo_telegramme.png'},
                            {image: '/images/products/wemap-print/logo_ona.png'}
                        ]}
                    />


                    <SectionOnlyFooter
                        titleSection={t('TITLE_PRINT')}
                        cellParagraph={t('DESCRIPTION_ENRICH_PUBLICATION')}
                        pictureRight={data.print.childImageSharp}
                        altDescription='Open book with Wemap map'
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_HIGH_RESOLUTION')
                            },
                            {
                                icon: 'icon-printer',
                                text: t('DESCRIPTION_IMPRESSION')
                            },
                            {
                                icon: 'icon-paragraph-left',
                                text: t('DESCRIPTION_LEGEND')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_MAPS_TECHNOLOGY')}
                            text={t('DESCRIPTION_PRINT')}
                            titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_USE_POWER')}
                        pictureRight={data.webApps.childImageSharp}
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        widthPicture='400px'
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_NEWS')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_TRAVEL_GUIDES')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_MULTI_LANGUAGE')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE')
                            }
                        ]}
                    />

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with poster of Wemap map'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  print: file(relativePath: {eq: "products/wemap-print/wemap_print.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 522, layout: FIXED)
    }
  }
  banner: file(relativePath: {eq: "products/wemap-print/wemap_print-banner.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
}
`;

